import type { ICategory } from '../types/model'

export default function useCategory () {
  /**
   * 타입별 카테고리 목록을 가져옵니다.
   * @param categoryList 카테고리 목록
   * @param type 타입 - MST_CATEGORY_TYPE
   * @returns
   */
  const getCategoryListByType = (categoryList: ICategory[], type: string) => {
    return categoryList
      .filter((category: ICategory) => category.categoryType === type)
      .map((category: ICategory) => {
        return {
          id: category.categoryNo,
          name: category.categoryName,
          list: category.categoryList.map((category2: ICategory) => {
            return {
              id: category2.categoryNo,
              name: category2.categoryName
            }
          })
        }
      })
  }

  return {
    getCategoryListByType
  }
}
